import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import blurbs from "./briefing-guide-blurb.json"
import SEO from "../../components/seo"
import ContentFold from "../../components/page-content/content-fold"
import AssistanceCalculator from "../calculator/assistance-calculator"
import styles from "./briefing-guide.module.scss"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const BriefingGuide = ({ type }) => {
  const { t } = useTranslation()
  let guideType = type
  let guideBlurb = blurbs.filter(blurb => guideType === blurb.type)
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorBanner: file(relativePath: { eq: "doctor-banner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          patientBanner: file(relativePath: { eq: "patient-banner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          medicineAssistance: file(relativePath: { eq: "prescription.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          motorcycle: file(relativePath: { eq: "moped.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          doctor: file(relativePath: { eq: "doctor.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ambulance: file(relativePath: { eq: "ambulance.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          curriculum: file(relativePath: { eq: "curriculum.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          smartphone: file(relativePath: { eq: "smartphone.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          laptop: file(relativePath: { eq: "laptop.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          medicine: file(relativePath: { eq: "medicine.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          longEnvelope: file(relativePath: { eq: "long-envelope.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <SEO
              title="PCP Briefing Guides"
              keywords={[
                "novartis",
                "medgrocer",
                "pulse care program",
                "entresto",
              ]}
            />
            <Img
              fluid={data[`${guideBlurb[0].bannerImage}`].childImageSharp.fluid}
              style={{ maxHeight: "350px" }}
            />
            <ContentFold
              title={t(guideBlurb[0].heading)}
              titleSize={3}
              subtitle={t(guideBlurb[0].subtitle)}
            >
              <div className="control">
                <Link
                  type="submit"
                  className={classNames(
                    styles.buttonSize,
                    "button is-link is-medium"
                  )}
                  to={type === "doctor" ? "/enroll-doctor" : "/enroll-patient"}
                >
                  {type === "doctor"
                    ? t("Register as Doctor")
                    : t("Enroll as Patient")}
                </Link>
              </div>
              <br />
              {type === "patient" && (
                <>
                  <p className="pt-1 is-size-5">
                    {t("Already enrolled in the Pulse Care Program?")}
                    <div className="buttons mt-1 pb-3">
                      <Link
                        to="/cart"
                        className={classNames(
                          styles.buttonSize,
                          "button is-primary is-medium"
                        )}
                      >
                        {t("Order Now")}
                      </Link>
                    </div>
                  </p>
                  {/* <AssistanceCalculator unfixButtons={true} /> */}
                </>
              )}
            </ContentFold>
            <ContentFold
              title={`${t(guideBlurb[0].valuePropTitle)}:`}
              hasBackground
              isCentered
              titleSize={5}
              // removePadding
            >
              <div className="columns is-multiline ">
                {guideBlurb[0].valueProp.map((item, index) => {
                  return (
                    <div className="column is-12 p-2">
                      <div className={styles.media} key={index}>
                        <figure className="media-left">
                          <p className="image is-64x64">
                            <Img
                              fluid={data[`${item.icon}`].childImageSharp.fluid}
                            />
                          </p>
                        </figure>

                        <div className="content">{t(item.description)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </ContentFold>
            <ContentFold
              title={t("Registration Steps")}
              isCentered
              className="my-1"
            >
              {guideBlurb[0].registrationSteps.map((item, index) => (
                <div className={classNames(styles.media, "pb-2")} key={index}>
                  <figure className="media-left">
                    <p className="image is-64x64">
                      <Img fluid={data[`${item.icon}`].childImageSharp.fluid} />
                    </p>
                  </figure>
                  <div className="media-content">
                    <strong>{t(item.title)}</strong>
                    <br />
                    {t(item.description)}
                  </div>
                </div>
              ))}
            </ContentFold>
            <ContentFold
              title={t("Mechanics")}
              isCentered
              hasBackground
              className="my-1"
            >
              <div className="content">
                <ol>
                  {guideBlurb[0].mechanics.map((step, index) => (
                    <li key={index}>{t(step)}</li>
                  ))}
                </ol>
              </div>
            </ContentFold>

            {type === "doctor" && (
              <ContentFold>
                <div className="has-text-centered">
                  <a
                    className="button is-primary is-medium"
                    href={process.env.GATSBY_BRIEFING_GUIDE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon is-medium">
                      <FontAwesomeIcon icon={faDownload} />
                    </span>
                    <span>{t("Download Guides")}</span>
                  </a>
                  <div className="is-size-7 mt-1">
                    Briefing Guides.pdf (1.5MB)
                  </div>
                </div>
              </ContentFold>
            )}
          </>
        )
      }}
    />
  )
}

export default BriefingGuide
