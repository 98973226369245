import React from 'react'
import Layout from '../components/layout'
import BriefingGuide from '../components/briefing-guide/briefing-guide'
import SEO from '../components/seo'

const Doctor = props => (
  <Layout location={props.location}>
    <SEO
      title="Be a Partner Doctor"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <BriefingGuide type="doctor" />
  </Layout>
)

export default Doctor
